<template>
  <div id="app">
    <div class="container">
      
      <div class="py-5 text-center">
        <img src="./assets/nuevoLogo.png">
        <h2>Fichas de Seguridad</h2>
        <p class="lead">Aquí debajo puede encontrar una <strong>Lista de Artículos</strong> que pueden ser adquiridos en <a href="https://suministros.casi.es/es/iniciar-sesion?back=my-account">Suministros de CASI</a> y las <strong>fichas de seguridad</strong> de esos artículos en formato pdf.</p>
      </div>

      <div class="row">
        <div class="col-md-12 order-md-1">
          <h4 class="mb-3">Artículos</h4>
          <input v-model="textoBusqueda" id="cajaBusqueda" class="form-control form-control-lg" type="text" placeholder="Escribe artículo para buscar...">
          <div class="mb-3">
            <ul class="list-group">
              <li 
                class="list-group-item d-flex justify-content-between align-items-center" 
                v-for="articulo in articulosFiltrados"
                :key="articulo.ID">
                <a href="#" @click="obtenerDatosPdf(articulo.ID);" title="Haz click para descargar PDF">
                  <i class="fas fa-file-pdf"></i> {{articulo.NOMBRE}}  
                </a>
                <span class="badge badge-danger" v-if="articulo.RECIENTE == 1">¡ACTUALIZADO!</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
  
      <div class="modal fade" id="miModal">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <footer class="my-5 pt-5 text-muted text-center text-small">
        <p class="mb-1">&copy; 2019-2021 CASI</p>
        <!--<ul class="list-inline">
          <li class="list-inline-item"><a href="#">Privacidades</a></li>
          <li class="list-inline-item"><a href="#">Términos</a></li>
          <li class="list-inline-item"><a href="#">Ayuda</a></li>
        </ul>-->
      </footer>

    </div>
  </div>
</template>

<script>

import axios from 'axios'

export default {
  name: 'App',
  components: {

  },
  data(){
    return {
      textoBusqueda:'',
      articulos:[],
      idArticuloSeleccionado:'',
      urlBaseApi: 'https://api-casi.casi.es:3005/'
    }
  },
  computed: {
    articulosFiltrados: function(){
      var self = this;
  
      if (self.articulos.length != 0)
        return self.articulos.filter(function(articulo){
          return articulo.NOMBRE.toUpperCase().includes(self.textoBusqueda.toUpperCase());
        });
      
      return '';
    }
  },
  mounted() {
    this.obtenerDatos();
  },
  methods: {
    obtenerDatos: function(){
      var self = this;

      axios.get(self.urlBaseApi + 'api/cliente/fichas-seguridad/').then(function(respuesta){
        self.articulos = respuesta.data[0];
      });
    },
    obtenerDatosPdf: function(id){
      var self = this;
      //console.log(id);
      self.idArticuloSeleccionado=id;
      axios.get(self.urlBaseApi + 'api/cliente/fichas-seguridad/' + id).then(function(respuesta){
        //console.log(respuesta.data[0][0].PDF);
        //console.log(respuesta.data[0][0].PDF.data);
        var byteArray = new Uint8Array(respuesta.data[0][0].PDF.data);
        var blob = new Blob([byteArray], { type: 'application/pdf' });
        self.mostrarPDF(blob);
      });
    },
    mostrarPDF: function(blob){
      const data = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = data;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        console.log('in');
        link.download=`fichaSeguridad_${this.idArticuloSeleccionado}.pdf`;
      }
      if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
        console.log('in2');
        link.download=`fichaSeguridad_${this.idArticuloSeleccionado}.pdf`;
      }
      
      link.target = '_blank';
      
      link.click();
      setTimeout(function(){
        window.URL.revokeObjectURL(data);
      }, 100);
    }
  }
}
</script>

<style>
  h2{
    padding-top:30px;
  }
  #cajaBusqueda{
    margin-bottom:30px;
  }
</style>
